input, button {
    appearance: none;
    background: none;
    border: none;
    outline: none;

    font-family: 'Poppins', sans-serif;
    box-shadow: inset 0 0 0 1px #bbbbbb;
}

form {
    display: block;
    position: relative;
}

form::after {
    content: '';
    display: block;
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    z-index: 1;
    background-image: linear-gradient(to bottom right, #fa6e6e, rgb(74, 65, 65));
}

form .form-inner {
    display: block;
    position: relative;
    z-index: 2;
    padding: 20px;
    background: #fff;
}

form .form-inner h2 {
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #333;
}

form .form-inner .form-group {
    display: block;
    position: relative;
    margin-bottom: 20px;
    width: 300px;
}

form .form-inner .form-group label {
    display: block;
    position: relative;
    margin-bottom: 5px;
    font-size: 0.9rem;
    color: #333;
    transition: 0.4s;
}

form .form-inner .form-group input {
    display: block;
    position: relative;
    width: 100%;
    padding: 10px 20px;
    font-size: 1rem;
    color: #333;
    border-radius: 5px;
    transition: 0.4s;
}

form .form-inner .form-group input:focus {
    box-shadow: 0 0 0 2px #fa6e6e;
}

form .form-inner .form-group input:focus + label {
    transform: translateY(-20px);
    font-size: 0.8rem;
}

form .form-inner .form-group:focus-within label{
    color: #fa6e6e;
}

form .form-inner .form-group input:focus {
    border-color: #fa6e6e;
    box-shadow: 0 0 0 2px #fa6e6e;
}

form .form-inner input[type="submit"] {
    display: block;
    position: relative;
    width: 100%;
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    border-radius: 5px;
    background: #fa6e6e;
    cursor: pointer;
    transition: 0.4s;
}

form .form-inner input[type="submit"]:hover {
    background: #f55;
}

form .form-inner input[type="submit"]:focus {
    box-shadow: 0 0 0 2px #fa6e6e;
}

.loading {
    display: block;
    position: relative;
    width: 100%;
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    border-radius: 5px;
    background: #fa6e6e;
    cursor: pointer;
    transition: 0.4s;

    animation: loading 1s infinite;
}

@keyframes loading {
    0% {
        background: #fa6e6e;
    }
    50% {
        background: rgb(228, 162, 162);
    }
    100% {
        background: #fa6e6e;
    }
}