.playerCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 400px;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    transition: all 0.3s ease-out;
    cursor: pointer;
    position: relative;

    margin: 1rem;
}

.Blue {
    background-color: rgb(187, 209, 155, 0.75);
    position: absolute;
}

.Red {
    background-color: rgb(243, 145, 145, 0.8);
    position: absolute;
}

.playerCard:hover {
    transform: scale(1.03);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26), 0 0.5rem 1rem rgba(0, 0, 0, 0.26);
}

.playerCard__image {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-radius: 0.5rem 0.5rem 0 0;
    margin: 2rem;
}

.playerCard__image img {
    width: 100px;
    height: 100px;
    object-fit: contain;
    border-radius: 0.5rem 0.5rem 0 0;
    margin-top: 0.5rem;
}

.playerCard__info {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0.5rem;
}

.playerCard__info h1 {
    font-size: 1.5rem;
}

.playerCard__info h2 {
    font-size: 1rem;
    white-space: pre-wrap;
}

.playerCard__info img {
    width: 40px;
    height: 40px;
    object-fit: contain;
    margin: 0.5rem 0.5rem;
}

.playerCard__info_name {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 1rem 0rem;
}

.playerCard__info_elo,
.playerCard__info_agent {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

/* hide info on hover */
.playerCard:hover .playerCard__info, 
.playerCard:hover .playerCard__image {
    display: none;
}

/* show player loadout on hover */
.playerCard__loadout {
    display: none;
}

.playerCard:hover .playerCard__loadout{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.playerCard__loadout {
    position: absolute;

    width: 100%;
    height: 100%;
    padding: 0.5rem 1.5rem;
    border-radius: 0.5rem;
    transition: all 0.3s ease-out;

    overflow-y: scroll;
    overflow-x: hidden;
}

/* scrollbar hide */
.playerCard:hover .playerCard__loadout::-webkit-scrollbar {
    display: none;
}

/* loadout item */
.playerCard__loadout_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 100px;
    padding: 1rem;
    margin: 1rem;

    border: 1px solid #ccc;
    background-color: rgba(204, 204, 204, 0.808);
    border-radius: 0.5rem;

    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    transition: all 0.3s ease-out;
}

.playerCard__loadout_item img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.playerCard__loadout_item h1 {
    display: none;
    position: absolute;
}

/* loadout item hover */
.playerCard__loadout_item:hover {
    flex-direction: row;
    transform: scale(1.03);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26), 0 0.5rem 1rem rgba(0, 0, 0, 0.26);
    background-color: #ccc;
}

.playerCard__loadout_item:hover h1 {
    display: block;
    font-size: 0.75rem;
    color: rgba(255, 255, 255, 0.658);

    text-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25);
    bottom: 0;
    right: 0;
    margin: 0.5rem;
}